import React from "react";

import { Container } from "react-bootstrap";

import Page from "components/Page/Page";

const config = [
  {
    name: "Aboriginal Artwork",
    moduleComponentName: "AboriginalArtworkBanner",
    rowProps: {},
    moduleProps: { encapsulate: true },
  },
  {
    name: "Upcoming events",
    moduleComponentName: "SectionHeading",
    rowProps: {
      className: "py-3 bg-white text-primary",
      noGutters: true,
    },
    moduleProps: {
      heading: {
        priority: 2,
        style: {
          fontWeight: "bold",
        },
        text: "Upcoming events",
      },
      separator: {
        style: {
          width: "100%",
        },
      },
      showClosedSubmissionAlert: true,
    },
    wrapWithContainer: true,
  },
  {
    name: "Visit us",
    id: "Visit us",
    moduleComponentName: "SectionContent",
    columnProps: {
      xs: 12,
      className: "mb-3",
    },
    rowProps: { noGutters: true },
    moduleProps: {
      elementId: "visit-us",
    },
  },
];
const VisitUs = () => {
  return (
    <Container fluid className="page-view">
      <Page config={config} />
    </Container>
  );
};

export default VisitUs;
